// TODO: These should be removed and any imports replaced with the _media-queries partial
// This is because this file needs to be imported to use the sass variables but it will bloat the importing
// component as it will add all the root css custom props again.
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

:root {
  // Neutral Colors
  --kp-white: #fff;
  --kp-gray-00: #fcfcfc;
  --kp-gray-01: #f5f5f5;
  --kp-gray-02: #f7f7f7;
  --kp-gray-03: #f7f9f9;
  --kp-gray-04: #f9feff;
  --kp-gray-05: #fffff9;
  --kp-gray-06: #ebebeb;
  --kp-gray-08: #e4edf9;
  --kp-gray-15: #d9d9d9;
  --kp-gray-20: #cdd7e4;
  --kp-gray-35: #a5a5a5;
  --kp-gray-50: #808080;
  --kp-gray-55: #787878;
  --kp-gray-65: #595959;
  --kp-gray-67: #555555;
  --kp-gray-70: #4a4f55;
  --kp-gray-73: #444444;
  --kp-gray-87: #222222;
  --kp-gray-90: #212121;
  --kp-gray-99: #000029;
  --kp-black: #000;

  // Other colors
  --kp-border-radius: 0.5rem;
  --kp-border-small-radius: 0.35rem;
  --kp-table-row-hover-color: #edeef0;
  --kp-background-color: #f5f8fa;
  --kp-outline-color: #eceff2;
  --kp-search-area-height: 3rem;
  --kp-filter-menu-height: 28.25rem;
  --kp-primary-color: #003064;
  --kp-secondary-color: #56708b;
  --kp-blue-light: #ebf4ff;
  --kp-teal: #118ab3;
  --kp-teal-light: #23a6d2;
  --kp-brown: #634200;
  --kp-brown-lighter: #fff3de;
  --kp-yellow: #ffc409;
  --kp-cyan: #005663;
  --kp-cyan-lighter: #c8e1e5;
  --kp-cyan-darker: #3399a8;
  --kp-purple: #260063;
  --kp-purple-lighter: #ded3f0;
  --kp-red: #c1413e;
  --kp-red-light: #f3c0c0;
  --kp-dark-red: #640f00;
  --kp-green: #4caf50;
  --kp-green-lighter: #f6fef9;
  --kp-secondary-shade: #d7e9fe;
  --kp-secondary-tint: #ebf3ff;
  --kp-tertiary-shade: #7294c1;
  --kp-light-tint: #f6f7f9;
  --kp-surface-3: #f5f7fa;
  --kp-orange: #f76b15;
  --kp-chart-blue: #1566d1;
  --kp-tertiary-dark: #728eb8;
  --kp-failure-red: #C6474B;
}
