:root {
  // Definitions
  --kp-brand-color: #1566d1;
  --kp-error: #cb5351;
  --kp-warn: #f5a623;
  --kp-success: #4caf50;
  --kp-surface-3: #f5f7fa;

  // Link colors to components
  --kp-button-background: var(--kp-brand-color);
  --kp-button-background-hover: var(--kp-primary-color);
  --kp-button-color: var(--kp-white);

  --kp-input-background: var(--kp-surface-3);
  --kp-input-border: var(--kp-outline-color);
  --kp-input-color: var(--kp-primary-color);
  --kp-input-border-focus: var(--kp-brand-color);
  --kp-input-label-color: var(--kp-primary-color);
  --kp-input-error-color: var(--kp-error);
}

html {
  background: var(--kp-background-image), linear-gradient(#fffff0 1.2%, #e0eeff 98.9%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100%;

  // Stop page scroll bounce on iOS
  overscroll-behavior: none;
  overflow-y: auto;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}

input,
select {
  background: var(--kp-input-background);
  padding: 0.6rem;
  border-radius: 0.25rem;
  border: none;
  outline: 1px solid var(--kp-input-border);
  color: var(--kp-input-color);
  font-size: 1rem;

  &::placeholder {
    color: var(--kp-input-placeholder-color);
  }

  &:focus {
    outline: 2px solid var(--kp-input-border-focus);
  }

  &.ng-invalid.ng-touched {
    outline-color: var(--kp-input-error-color);
  }
}

label {
  color: var(--kp-input-label-color);
  margin-bottom: 0.2rem;
  display: block;
}

button.kp-button {
  background: var(--kp-button-background);
  color: var(--kp-button-color);
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    background: var(--kp-button-background-hover);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

button.kp-secondary-button {
  display: block;
  width: 100%;
  color: var(--kp-brand-color);
  border: 1px solid var(--kp-brand-color);
  border-radius: 0.5rem;
  font-size: 1rem;
  background: unset;
  font-weight: 500;
  padding: 0.5rem 0;

  &:hover {
    cursor: pointer;
    background: var(--kp-blue-light);
  }
}

button.kp-tertiary-button {
  display: block;
  width: 100%;
  color: var(--kp-secondary-color);
  border: 1px solid var(--kp-secondary-color);
  border-radius: 0.5rem;
  background: unset;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 0;

  &:hover {
    cursor: pointer;
    background: var(--kp-gray-06);
  }
}

button.text-button {
  background: none;
  color: var(--kp-brand-color);
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  display: inline;
  width: auto;

  &:hover {
    color: var(--kp-primary-color);
    text-decoration: underline;
  }
}

h4 {
  color: var(--kp-secondary-color);
  font-weight: normal;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

h3 {
  color: var(--kp-primary-color);
  font-weight: 500;
  font-size: 1.25rem;
}

h2 {
  margin-top: 0;
  text-align: center;
  color: var(--kp-primary-color);
  font-weight: bold;
  font-size: 1.5rem;
}

hr {
  margin: 1.5rem -3rem;
  background: var(--kp-outline-color);
  height: 1px;
  border: none;
}

.bold {
  font-weight: 700;
}
